
body {
    height: 100% !important;
    width: 100% !important;
    overflow: auto !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
}

#pdf-download, #file-name {
  display: none;
}
@media only screen and (max-width: 475px){
  .slides {
    height: 35vh;
    margin-top:4rem;
    background-size: 100vw 40vh !important;
  }
  .packImage{
    /* width: 100vw !important; */
    height: 42vh;
    background-size: 100vw 40vh !important;
  }
  .hash{
    height: 42vh;
    background-size: contain !important;
  }
  .inner-banner-main img{
    height: 30vh;
    padding-top: 60px;
  }
  .diet-head {
    margin-top: .5rem;
  }
  .innerpage-section{
    padding-top: 0px;
  }
  /* .inner-banner-main{ padding-top: 1.6rem;} */
  header{
    width: 100vw;
    position: fixed;
    z-index: 999;
  }
    .login{
      margin-right: 30px;
    }
  .double {
    float: none;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: 10px;
  }
  .strip{
    width: 100%;
    height: 10vh;
    background-color: rgb(23, 37, 99);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .strip_add{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* border: 1px solid red; */
  }
  .strip_add  .checked {
    color: rgb(255, 255, 255);
    margin-right: 0.5rem;
  }
  .strip_add p{
    font-weight: 500;
    color: #fff;
    font-size: 15px;
    /* letter-spacing: 1px; */
  }
  .top-left-hiitfit{
    margin-top: 0.5rem;
  }
  .download-app{
    margin-top: 0px;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .download-app img{
    width: 300px;
  }
  .healthy-lifestyle-img{
    background-size: 87vw  60vh;
  }
  .jumbotron{
    height:23vh;
    margin-top: 55px;
  }
  .jumbotron video{
    object-fit:fill
  }
  .mBtn{
    border: none;
    background: none;
  }
  .pdf-div{
    padding: 10px;
    background-color: #dedede8a;
    margin-top: 50px;
  }
  canvas.react-pdf__Page__canvas {
    margin-bottom: 20px;
    width: 100% !important;
    height: fit-content !important;
  }
}
@media screen and (min-width:1440px) and (max-width:2000px) {
  .diet-plan-listings img {
    height:150px ;
    width: 300px;
  }
  .strip{
    width: 100%;
    height: 6vh;
    background-color: rgb(23, 37, 99);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .strip_add{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* border: 1px solid red; */
  }
  .strip_add  .checked {
    color: rgb(255, 255, 255);
    margin-right: 3rem;
  }
  .strip_add p{
    font-weight: 600;
    color: #fff;
    font-size: 23px;
    letter-spacing: 1px;
  }
  .top-left-hiitfit {
    float: left;
    /* border: 1px solid red; */
    margin-top: 1rem !important;
    /* padding-left: 2%;
    padding-top: 23.5px; */
    /* background-color: red; */
  }
}
@media screen and (min-width:476px) and (max-width:1440px) {
  .diet-plan-listings img {
    height:150px ;
    width: 300px;
  }
  .strip{
    width: 100%;
    height: 8vh;
    background-color: rgb(23, 37, 99);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .strip_add{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* border: 1px solid red; */
  }
  .strip_add  .checked {
    color: rgb(255, 255, 255);
    margin-right: 1rem;
  }
  .strip_add p{
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .top-left-hiitfit {
    float: left;
    /* border: 1px solid red; */
    margin-top: 0.1rem !important;
    /* padding-left: 2%;
    padding-top: 23.5px; */
    /* background-color: red; */
  }
 
}
.double select{
  width: 100%;
  outline: none;
  padding: 8px 15px;
  border: 1px solid rgba(196, 191, 191, 0.685);
}
.policy{
  max-height: 800px;
  overflow-x: auto;
  scrollbar-width: none;
}
.policy::-webkit-scrollbar {
  width: 1px;
  background-color: #F5F5F5;
}

select.form-control { 
  -webkit-appearance: menulist!important;
    -moz-appearance: menulist!important;
    -ms-appearance: menulist!important;
    -o-appearance: menulist!important;
    appearance: menulist!important;
}

.hitSubs{
  color: #fff;
  cursor: pointer;
  transition: all ease 300ms;
}
.hitSubs:hover{
  background-color: #1668b0c0;
}

.lnks li{
  color: #fff;
  padding-right: 3rem;
}

.lnks li:hover{
  color: aquamarine;
}

.log {
  border: 1px solid red;
  cursor: pointer !important;
}

.blogs{
  margin-top: 3rem;
}

.araList{
  text-align: right !important;
}
.webImg{
  /* border: 1px solid red; */
  width: 100%;
  padding: 0 10px !important;
  
}

.blogText{
  color: rgba(0, 0, 0, 0.664);
  font-size: 17px;
  font-weight: 500;
}
.blogText a{
  color: #fff;
  padding-left: 5px;
  text-decoration: underline;
}


.datCard{
  display: flex;
  justify-content: center;
  align-items: center;
}

.datCard img{
  height: 450px;
  object-fit: contain;
  border: none;
}

.clsIcon{
  /* height: 25px; */
  font-size: 22px;
  color: rgba(92, 89, 89, 0.712);
  left: 180px;
}

.pdf-div{
  padding: 20px;
  background-color: #dedede8a;
  margin-top: 50px;
}
canvas.react-pdf__Page__canvas {
  margin-bottom: 20px;
  width: 100% !important;
  height: fit-content !important;
}

.play_btn{
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 7px;
  background: #229CF6;
  color: #fff;
  font-weight: 600;
  border: 1px solid #229CF6;
  transition: 200ms all ease;
  text-decoration: none;
}
.play_btn:hover{
  color: #229CF6;
  background: #fff;
}